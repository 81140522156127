import React from "react"
import styled from "@emotion/styled"
import { FaCircle } from "react-icons/fa"

import Project from "../../components/project"
import Image from "../../components/image"
import { color } from "../../utils"

import titleAnimMp4 from "../../images/mcm/title-animation.mp4"
import titleAnimWebm from "../../images/mcm/title-animation.webm"

const Container = styled.article`
  margin: 0;
  padding: 2rem;
  color: ${color.textBackgroundBlack};
  font-size: 1.1rem;
  line-height: 2rem;
  background-color: ${color.backgroundTransparencyBlack};
  opacity: 0;

  animation: fade 1.2s ease-in-out forwards;
  @keyframes fade {
    to {
      opacity: 1;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 3% 10%;
    padding: 2rem 4rem;
    font-size: 1.2rem;
    line-height: 2.5rem;
  }
`
const Title = styled.h1`
  &.title {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
    color: #ffffffa8;
    font-weight: 900;
    font-family: "MuseoModerno", cursive;
  }

  @media screen and (min-width: 768px) {
    &.title {
      font-size: 2rem;
      margin: 1rem 0 3rem 0;
    }
  }
`
const SubTitle = styled.h2`
  &.subtitle {
    font-family: "MuseoModerno", cursive;
    font-size: 1.4rem;
    color: #fb3c76;
  }
`
const TextMain = styled.p`
  text-justify: auto;
  font-size: 1.1rem;
  margin-bottom: 1.7rem;

  span {
    font-size: 1.2rem;
    color: #ff9db1;
  }

  a,
  a:visited {
    color: inherit;
    text-decoration: underline;
  }

  a:hover {
    color: #fb3c76;
  }

  @media screen and (min-width: 768px) {
  }
`
const ImageContainer = styled.div`
  display: grid;

  &.mobile-pics,
  &.desktop-pics {
    column-gap: 10px;
    grid-template-columns: 1fr;
  }
  &.motion-block {
    display: flex;
    flex-flow: column wrap;
  }

  video {
    max-width: 70%;
    margin: 1rem auto;
    object-fit: contain;
  }

  img {
    object-fit: cover;
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: 500px) {
    &.mobile-pics {
      column-gap: 2rem;
      row-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr auto;
    }
    &.motion-block {
      flex-flow: row nowrap;
    }
    video {
      max-width: 20vw;
      margin: 1rem 0 0 1rem;
    }
  }
  @media screen and (min-width: 800px) {
    &.motion-block {
    }
  }

  @media screen and (min-width: 1000px) {
    &.mobile-pics {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }
    &.desktop-pics {
      column-gap: 2rem;
      grid-template-columns: 1fr 1fr;
    }
    video {
      max-width: 15vw;
    }
  }
`

const EvoDevo = () => {
  return (
    <Project title="Margarida's Website">
      <Container>
        <Title className="title">Margarida's Website</Title>

        <SubTitle className="subtitle">Type of product:</SubTitle>
        <TextMain>Personal website</TextMain>
        <SubTitle className="subtitle">Role:</SubTitle>
        <TextMain>Design and frontend development</TextMain>
        <SubTitle className="subtitle">Tech stack:</SubTitle>
        <TextMain>HTML, CSS, Javascript, jQuery, GitLab</TextMain>
        <SubTitle className="subtitle">Description of the project:</SubTitle>
        <TextMain>
          My client, Margarida, was a post-doc about to look for a PI (principal
          investigator) job position. So we worked together in order to create a
          simple but elegant{" "}
          <a
            href="https://cardosomoreira.gitlab.io/"
            target="_blank"
            rel="noreferrer"
          >
            website
          </a>
          , where she could show her academic and professional path, as well as
          share some resources and some personal recommendations.
        </TextMain>
        <ImageContainer className="mobile-pics">
          <Image
            fileName="mcm-mobile-welcome.png"
            alt="Mobile view of homepage"
          />
          <Image
            fileName="mcm-mobile-publications.png"
            alt="Mobile view of publications page"
          />
          <Image
            fileName="mcm-mobile-resources.png"
            alt="Mobile view of resources page"
          />
          <Image
            fileName="mcm-mobile-books.png"
            alt="Mobile view of book recommendations page"
          />
        </ImageContainer>
        <TextMain>
          Although this is a simple website, where I used just HTML, CSS and
          JavaScript, I really enjoyed working on it. Sometimes it's good to use
          only the most basic tools and develop core skills (once in a while I
          did miss some helpers provided by frameworks! ok... I admit it... I
          resorted to some jQuery!{" "}
          <span role="img" aria-label="emoji blushing face">
            🤭
          </span>
          ). Also, I was able to focus on frontend development, which is
          definitely what I love the most.
        </TextMain>
        <TextMain>
          Version control was done using Git/GitLab. Deployment was made using
          GitLab pages.
        </TextMain>

        <ImageContainer className="desktop-pics">
          <Image fileName="mcm-desktop-cv.png" alt="Desktop view of CV page" />
          <Image
            fileName="mcm-desktop-resources.png"
            alt="Desktop view of resources page"
          />
        </ImageContainer>
        <ImageContainer className="desktop-pics">
          <Image
            fileName="mcm-desktop-books.png"
            alt="Desktop view of book recommendations page"
          />
          <Image
            fileName="mcm-desktop-publications.png"
            alt="Desktop view of publications page"
          />
        </ImageContainer>
        <TextMain>
          By the way, Margarida is about to lead her own research group at The
          Francis Crick Institute, in London.{" "}
          <span role="img" aria-label="emoji party popper">
            🎉
          </span>
        </TextMain>

        <SubTitle className="subtitle">Design:</SubTitle>
        <TextMain>
          - <span>Layout:</span> I created a fixed navigation bar on top, shared
          by every page, and the main content was delivered in the rest of the
          space, according to the specific layout of each page. In mobile, the
          menu is activated by a hamburger button. The site is fully responsive.{" "}
        </TextMain>
        <TextMain>
          - <span>Colors:</span> I decided to create a lighter website, with a
          contrasting color palette. So I used white (
          <FaCircle
            style={{ color: "#FFF", fontSize: "0.7rem", margin: "0 4px 0 2px" }}
          />
          #FFF) for the background and a dark cyan (
          <FaCircle
            style={{
              color: "#3A8988",
              fontSize: "0.7rem",
              margin: "0 4px 0 2px",
            }}
          />
          #3A8988) for the navigation bar. These two colors were also used for
          text, and an eerie black (
          <FaCircle
            style={{ color: "#222", fontSize: "0.7rem", margin: "0 4px 0 2px" }}
          />
          #222) was used for general writing, as it provides good contrast.
        </TextMain>
        <TextMain>
          - <span>Typography:</span> Two typefaces were used throughout the
          site: 'Poiret One' for titles and navigation, and 'Open Sans' for
          general text.{" "}
        </TextMain>
        <ImageContainer className="motion-block">
          <TextMain>
            - <span>Motion:</span> Some transitions were used, but only to make
            the UX a bit more smooth. The most obvious animation is on the title
            of the current page: I wanted to create some motion in order to call
            attention to the current location on the site. I did this because
            the title itself is positioned in the navigation bar, instead of the
            "main area". I also used some motion for the menus.{" "}
          </TextMain>
          <video autoplay loop muted playsinline>
            <source src={titleAnimWebm} type="video/webm" />
            <source src={titleAnimMp4} type="video/mp4" />
          </video>
        </ImageContainer>
      </Container>
    </Project>
  )
}

export default EvoDevo
